import { add, differenceInDays, intlFormat, isDate } from 'date-fns'

export const useDateTime = () => {
  const { t } = useI18n()

  const dateFromNowByDays = (daysCount) => {
    const now = new Date()
    const targetDate = add(now, { days: daysCount })
    return targetDate
  }

  const distanceOrWeekdayFromNowByDays = (daysCount, localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const now = new Date()
    const targetDate = add(now, { days: daysCount })
    if (daysCount === 0) {
      const today =
        t('dateTime.today') +
        ', ' +
        intlFormat(now, { weekday: 'short' }, { locale })
      return today
    } else if (daysCount === 1) {
      const tomorrow =
        t('dateTime.tomorrow') +
        ', ' +
        intlFormat(targetDate, { weekday: 'short' }, { locale })
      return tomorrow
    } else {
      const result = intlFormat(
        targetDate,
        { weekday: 'short', day: 'numeric', month: 'short' },
        { locale }
      )
      return result
    }
  }

  const intDate = (isoDate, localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const date = new Date(isoDate)
    if (!isDate(date)) return null
    const result = intlFormat(
      date,
      { year: 'numeric', month: 'short', day: 'numeric' },
      { locale }
    )
    return result
  }

  const intShortDateTime = (isoDate, localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const date = new Date(isoDate)
    if (!isDate(date)) return null
    const result = intlFormat(
      date,
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      {
        locale,
      }
    )
    return result
  }
  const intSuperShortDateTimeFromNow = (isoDate, localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const date = new Date(isoDate)
    if (!isDate(date)) return null
    if (differenceInDays(new Date(), date) < 1) {
      return intlFormat(
        date,
        {
          hour: 'numeric',
          minute: 'numeric',
        },
        {
          locale,
        }
      )
    }
    if (differenceInDays(new Date(), date) < 365) {
      return intlFormat(
        date,
        {
          month: 'short',
          day: 'numeric',
        },
        {
          locale,
        }
      )
    }
    return intlFormat(
      date,
      {
        month: 'short',
        year: '2-digit',
      },
      {
        locale,
      }
    )
  }

  const memberSinceDate = (isoDate, localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const date = new Date(isoDate)
    if (!isDate(date)) return null
    const result = intlFormat(
      date,
      { year: 'numeric', month: 'long' },
      { locale }
    )
    return result
  }

  return {
    dateFromNowByDays,
    distanceOrWeekdayFromNowByDays,
    intDate,
    intShortDateTime,
    intSuperShortDateTimeFromNow,
    memberSinceDate,
  }
}
